
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {
        width: $length !important;
      }
    }
  }
}

$newPrimaryColor: #105374 !default;

.btn-primary {
  @include button-variant(
    $newPrimaryColor,
    darken($newPrimaryColor, 7.5%),
    darken($newPrimaryColor, 10%),
    lighten($newPrimaryColor, 5%),
    lighten($newPrimaryColor, 10%),
    darken($newPrimaryColor, 30%)
  );
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  color: #fff;
}

$body-color: #373E42;

@import "~bootstrap/scss/bootstrap";